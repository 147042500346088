import PropTypes from 'prop-types'

import styles from './user-display.module.scss'

export default function UserDisplay({ user }) {
  return (
    // return an inline-block display, self-contained nowrap element.
    <div className={styles['user-display']}>
      <img
        className={styles['profile-pic']}
        src={
          user?.media_attachment?.direct_upload_url
            ? user.media_attachment.direct_upload_url
            : '/images/default-user.png'
        }
        alt={
          user?.media_attachment?.upload_file_name
            ? user.media_attachment.upload_file_name
            : 'default profile image'
        }
      />
      <div>
        {user?.name}
        <br />
        <span className={styles.nickname}>{`@${user?.nickname}`}</span>
      </div>
    </div>
  )
}

UserDisplay.propTypes = {}

UserDisplay.defaultProps = {}
