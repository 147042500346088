export function toTitleCase(string) {
  return (
    string?.replace(
      /\w\S*/g,
      text => text.charAt(0).toUpperCase() + text.substr(1).toLowerCase(),
    ) || ''
  )
}

export function toCapitalizeWords(string) {
  return !!string
    ? string
        .toLowerCase()
        .split(' ')
        .map(w => w[0].toUpperCase() + w.substr(1))
        .join(' ')
    : ''
}

/** Formats an EIN to include a dash if it doesn't already */
export function formatEin(ein) {
  if (!ein) {
    return ''
  }
  if (ein.includes('-')) {
    return ein
  }
  const formatted = Array.from(ein)
  formatted.splice(2, 0, '-')
  return formatted.join('')
}

export default {
  formatEin,
  toTitleCase,
  toCapitalizeWords,
}
