import { useCallback } from 'react'
import axios from 'axios'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
const FB_APP_ID = process.env.REACT_APP_FB_APP_ID

export function initFacebookSdk() {
  return new Promise(resolve => {
    // Wait for facebook sdk to initialize before starting the react app
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: FB_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v13.0',
      })
      resolve()
    }

    // load facebook sdk script
    ;((d, s, id) => {
      const fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      const js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  })
}

export default function useFacebook() {
  const getProfile = useCallback(callback => {
    window.FB.api('/me?fields=email,name,picture.type(large)', response => {
      // eslint-disable-next-line no-console
      console.log('useFacebook getProfile', response)
      callback(response)
    })
  }, [])

  const login = useCallback(
    locale =>
      new Promise((resolve, reject) => {
        window.FB.login(
          response => {
            if (response.authResponse) {
              if (response?.status === 'connected') {
                const { accessToken } = response.authResponse
                getProfile(async facebookProfile => {
                  const pictureUrl = facebookProfile?.picture?.data?.url
                  const pictureUrlParam = pictureUrl
                    ? `&picture_url=${escape(pictureUrl)}`
                    : ''
                  const authResponse = await axios.get(
                    `${API_BASE_URL}facebook/auth.json?language=${
                      locale ?? 'en'
                    }&access_token=${accessToken}${pictureUrlParam}`,
                  )
                  // eslint-disable-next-line no-console
                  console.log({ authResponse })
                  if (authResponse) {
                    // eslint-disable-next-line no-console
                    console.log('resolve', { authResponse })
                    resolve(authResponse)
                  } else {
                    // eslint-disable-next-line no-console
                    console.log('reject', { authResponse })
                    reject()
                  }
                })
              } else {
                reject()
              }
            } else {
              reject()
            }
          },
          { scope: 'public_profile,email' },
        )
      }),
    [getProfile],
  )

  const logout = useCallback(() => {
    window.FB.getLoginStatus(response => {
      if (response.status && response.status === 'connected') {
        window.FB.logout()
      }
    })
  }, [])

  const logPageView = useCallback(() => {
    window.FB.AppEvents.logPageView()
  }, [])

  return {
    login,
    logout,
    logPageView,
    getProfile,
  }
}
