import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'

import { formatAmount } from '../../../lib/numbers'
import SortTable from '../../../components/sortTable/sortTable'
import Block from '../../../components/Dashboard/Block'
import BlockContent from '../../../components/Dashboard/BlockContent'
import Loading from '../../../components/loading'
import useApi from '../../../hooks/useApi'
import { useCurrentUser } from '../../../hooks/useCurrentUser'

export default function Disbursement({ charity }) {
  const { t } = useTranslation()
  const [showModalLetter, setShowModalLetter] = useState(false)
  const [sendCopy, setSendCopy] = useState(false)
  const [templateLetter, setTemplateLetter] = useState('')
  const [savedTemplateLetter, setSavedTemplateLetter] = useState('')
  const [recipients, setRecipients] = useState([])
  const [selected, setSelected] = useState([])
  const [resetCheckbox, setResetCheckbox] = useState(false)
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(null)
  const [disbursements, setDisbursements] = useState([])
  const [donations, setDonations] = useState([])
  const [totalDonations, setTotalDonations] = useState(0)
  const [totalDonors, setTotalDonors] = useState(0)
  const [sentDate, setSentDate] = useState(new Date())

  const { currentUser } = useCurrentUser()

  const {
    charitiesSendThankYouEmails,
    civDonationResendEmail,
    getCharityDisbursements,
    getCharityDonations,
    getCharityTemplateEmail,
    updateCharityTemplateEmail,
  } = useApi()

  const fetchData = useCallback(async () => {
    setLoading(true)
    // Get disbursements
    const { data: disbursementData } = await getCharityDisbursements(
      charity?.charity?.id,
    )
    if (disbursementData) {
      setDisbursements(disbursementData)
    }
    // Get Template Letter
    const { data: templateData } = await getCharityTemplateEmail(
      charity?.charity?.id,
    )
    if (templateData) {
      setTemplateLetter(templateData.custom_email_body)
      setSavedTemplateLetter(templateData.custom_email_body)
    }
    // Get donations
    const { data } = await getCharityDonations(charity?.charity?.id)
    if (data) {
      setDonations(data)
      setTotalDonations(
        data.reduce((acc, obj) => {
          return acc + obj.amount
        }, 0),
      )
      setTotalDonors(data.length)
    }
    setLoading(false)
  }, [charity, getCharityDonations, getCharityDisbursements])

  useEffect(() => {
    fetchData()
  }, [charity, refresh])

  const disbursementsHeaders = [
    {
      title: t('charities.dashboard.disbursement.headers.disbursementDate'),
      prop: 'date',
      isSortable: true,
    },
    {
      title: t('charities.dashboard.disbursement.headers.amount'),
      prop: 'amount',
      isSortable: true,
    },
  ]

  const donationsHeaders = [
    {
      alignment: {
        horizontal: 'center',
      },
      checkbox: {
        className: 'chk-checkbox',
        idProp: 'id',
      },
      prop: 'checkbox',
    },
    {
      title: t('charities.dashboard.donations.headers.date'),
      prop: 'date',
    },
    {
      title: t('charities.dashboard.donations.headers.donor'),
      prop: 'donor',
    },
    {
      title: t('charities.dashboard.donations.headers.amount'),
      prop: 'amount',
    },
    {
      title: t('charities.dashboard.donations.headers.thankYouLetter'),
      prop: 'actions',
    },
  ]

  const onCheckboxClick = selected => {
    setResetCheckbox(false)
    setSelected(selected)
  }

  const onClickViewLetter = donor => {
    // pending getting the letter content sent
    setRecipients(donations.filter(r => r.id === donor.id))
    setTemplateLetter(donor.custom_email_body)
    setSentDate(donor.email_sent_at)
    setShowModalLetter(true)
  }

  const onClickResendLetter = async donationId => {
    // console.log('Resend Letter to', donationId)
    setLoading(true)
    try {
      const response = await civDonationResendEmail(donationId)
      if (response?.status === 200) {
        setRefresh(+new Date())
      }
      setLoading(false)
    } catch (error) {
      console.log('Error', error)
      setLoading(false)
    }
  }

  const disbursementsBody = disbursements?.map(d => ({
    date: moment(d.disbursed_at).format(t('config.date_format')),
    amount: `${formatAmount(d.total_amount)} CIV`,
  }))

  const donationsBody = donations?.map(d => ({
    id: d.id,
    date: moment(d.created_at).format(t('config.date_format')),
    donor: d.donor_name,
    amount: `${formatAmount(d.amount)} CIV`,
    actions: !!d.email_sent_at ? (
      <Row>
        <Col className="col-6">
          {t('charities.dashboard.donations.sentOn', {
            date: moment(d.email_sent_at).format(t('config.date_format')),
          })}
        </Col>
        <Col className="col-3">
          <button
            type="button"
            className="btn btn-link p-0 text-decoration-none"
            onClick={() => onClickViewLetter({ ...d })}
          >
            {t('charities.dashboard.donations.viewLetter')}
          </button>
        </Col>
        <Col className="col-3">
          <button
            type="button"
            className="btn btn-link p-0 text-decoration-none"
            onClick={() => onClickResendLetter(d.id)}
          >
            {t('charities.dashboard.donations.resend')}
          </button>
        </Col>
      </Row>
    ) : (
      ''
    ),
  }))

  const onClickNext = () => {
    setRecipients(donations.filter(r => selected.includes(r.id)))
    setSentDate(new Date())
    setShowModalLetter(true)
  }

  const onClickViewTemplate = () => {
    setSelected([])
    setRecipients([])
    setShowModalLetter(true)
  }

  const onHide = () => {
    setTemplateLetter(savedTemplateLetter)
    setShowModalLetter(false)
  }

  const onClickModalSave = async () => {
    setLoading(true)
    try {
      const response = await updateCharityTemplateEmail(charity?.charity?.id, {
        custom_email_body: templateLetter,
      })
      if (response?.status === 200) {
        setSavedTemplateLetter(templateLetter)
        setShowModalLetter(false)
        setLoading(false)
      }
    } catch (error) {
      console.log('Error', error)
      setLoading(false)
    }
  }

  const onClickModalSend = async () => {
    setLoading(true)
    try {
      const data = {
        donation_ids: selected,
        custom_email_body: templateLetter,
        send_me_a_copy: sendCopy,
      }
      const response = await charitiesSendThankYouEmails(
        charity?.charity?.id,
        data,
      )
      if (response?.status === 200) {
        setResetCheckbox(true)
        setSelected([])
        setRecipients([])
        setRefresh(+new Date())
      }
      setShowModalLetter(false)
      setLoading(false)
    } catch (error) {
      console.log('Error', error)
      setLoading(false)
    }
  }

  return (
    <>
      <Loading show={loading} />
      <div className="container mt-4">
        <div className="row">
          <h1 className="fs-1 text-capitalize">
            {t('charities.dashboard.charity', {
              name: (charity?.charity?.organization_name).toLowerCase(),
            })}
          </h1>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-between">
          <Block>
            <BlockContent
              stat={`${formatAmount(totalDonations ?? 0)} CIV`}
              statLabel={t('charities.dashboard.totalDonations')}
            />
          </Block>
          <Block>
            <BlockContent
              stat={formatAmount(totalDonors ?? 0)}
              statLabel={t('charities.dashboard.donors')}
            />
          </Block>
          {/* <Block>
            <BlockContent stat={'PLACEHOLDER'} statLabel={''} />
          </Block>
          <Block>
            <BlockContent stat={'PLACEHOLDER'} statLabel={''} />
          </Block> */}
        </div>
      </div>
      <div className="container mt-4">
        <div className="row">
          <h3 className="text-start">
            {t('charities.dashboard.disbursement.title')}
          </h3>
        </div>
      </div>
      <div className="container mt-4">
        <SortTable
          body={disbursementsBody}
          headers={disbursementsHeaders}
          onFetch={s => console.log(s)}
          showPagination={false}
        />
      </div>
      <div className="container mt-4">
        <Row>
          <Col>
            <h3 className="text-start">
              {t('charities.dashboard.donations.title')}
            </h3>
          </Col>
          <Col className="d-flex align-items-end justify-content-end">
            <button
              type="button"
              className="btn btn-link p-0 text-decoration-none"
              onClick={() => onClickViewTemplate()}
            >
              {t('charities.dashboard.donations.thankYouLetter')}
            </button>
          </Col>
        </Row>
      </div>
      <div className="container mt-4">
        <SortTable
          body={donationsBody}
          headers={donationsHeaders}
          onFetch={s => console.log(s)}
          showPagination={false}
          onCheckboxClick={onCheckboxClick}
          resetCheckbox={resetCheckbox}
        />
      </div>
      <div className="container mt-4 text-end">
        <Button
          variant="primary"
          onClick={() => onClickNext()}
          disabled={!selected.length}
        >
          {t('charities.dashboard.donations.buttonNext')}
        </Button>
      </div>
      <Modal size="lg" show={showModalLetter} onHide={() => onHide()}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100 p-0">
            {t('charities.dashboard.donations.composeLetter.heading')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="pt-2 ps-3">
            <Col className="text-start">
              {t('charities.dashboard.donations.composeLetter.recipients', {
                count: recipients.length,
                names: recipients.map(r => r.donor_name).join(', '),
              })}
            </Col>
          </Row>
          <Row className="pt-2 ps-3">
            <Col>
              <input
                type="checkbox"
                onChange={e => setSendCopy(e.target.checked)}
              />
              <span className="ps-1">
                {t('charities.dashboard.donations.composeLetter.sendCopy')}
              </span>
            </Col>
          </Row>
          <Row className="pt-2 ps-3">
            <Col className="text-start defaultText text-capitalize">
              {(charity?.charity?.organization_name).toLowerCase()}
            </Col>
          </Row>
          <Row className="pt-2 ps-3">
            <Col className="text-start defaultText">
              {moment(sentDate).format(t('config.date_format'))}
            </Col>
          </Row>
          <Row className="pt-3 ps-3">
            <Col className="text-start defaultText">
              {t('charities.dashboard.donations.composeLetter.dearPlaceholder')}
            </Col>
          </Row>
          <Row className="pt-2">
            <Col>
              <textarea
                className="w-100 px-3"
                onChange={e => setTemplateLetter(e.target.value)}
                rows={10}
                defaultValue={templateLetter}
              />
            </Col>
          </Row>
          {/* <Row className="pt-2 ps-3">
            <Col className="text-start defaultText">{currentUser.name}</Col>
          </Row>
          <Row className="pt-2 ps-3">
            <Col className="text-start defaultText text-capitalize">
              {(charity?.charity?.organization_name).toLowerCase()}
            </Col>
          </Row> */}
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col className="text-end">
              <Button
                variant={!!recipients.length ? 'secondary' : 'primary'}
                onClick={() => onClickModalSave()}
              >
                {t('charities.dashboard.donations.composeLetter.save')}
              </Button>
            </Col>
            <Col className="text-end">
              <Button
                variant="primary"
                onClick={() => onClickModalSend()}
                hidden={!recipients.length}
              >
                {t('charities.dashboard.donations.composeLetter.send')}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  )
}
