import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { CharityType } from '../../../types'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import CharitySearchRow from '../search/row'
import Styles from '../charities.module.scss'
import TextInput from '../../../components/TextInput/TextInput'
import Uploader from '../../../components/uploader/uploader'
import useApi from '../../../hooks/useApi'
import useValidation from '../../../hooks/useValidation'

export default function CharityRegisterForm(props) {
  const { charity } = props
  const { createCharityRegistration } = useApi()
  const { currentUser } = useCurrentUser()
  const { t } = useTranslation()

  const [checkAttachment, setCheckAttachment] = useState(null)
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false)
  const [isUploadingCheck, setIsUploadingCheck] = useState(false)
  const [registration, setRegistration] = useState({
    charity_id: charity.id,
  })
  const [bankAccount, setBankAccount] = useState({})

  const { validate, validationErrors, setValidationError } = useValidation({
    validationRules: {
      accountHolderName: (value = '') =>
        value.length > 0 ? null : 'Name is required',
      bankAccountNumber: (value = '') => {
        if (value.length < 12 || value.length > 17) {
          return 'Routing number requires 12 to 17 digits'
        }
        return null
      },
      bankRoutingNumber: (value = '') => {
        return value.length >= 9 ? null : 'Account number requires 9 digits'
      },
    },
  })

  const goBack = useCallback(() => {
    window.history.back()
  }, [])

  const onHideSuccessModal = useCallback(() => {
    setIsSuccessModalVisible(false)
    goBack()
  }, [goBack])

  const onClickSubmit = useCallback(async () => {
    const isValid = validate({
      accountHolderName: bankAccount.bank_account_holder_name,
      bankAccountNumber: bankAccount.bank_account_number,
      bankRoutingNumber: bankAccount.bank_account_routing_number,
    })
    if (isValid) {
      setIsSuccessModalVisible(true)
      createCharityRegistration({
        charity_registration: {
          ...registration,
          bank_account_attributes: { ...bankAccount },
        },
        media_attachment_ids: [checkAttachment.id],
      })
    }
  }, [goBack, registration, checkAttachment])

  const canSubmit = useMemo(
    () =>
      !isUploadingCheck &&
      bankAccount?.bank_account_holder_name?.length > 0 &&
      bankAccount?.bank_account_number?.length > 0 &&
      registration?.charity_id &&
      checkAttachment,
    [isUploadingCheck, registration, bankAccount, checkAttachment],
  )

  const renderUploaderButtonCheck = useCallback(() => {
    const disabled = isUploadingCheck || !!checkAttachment
    return (
      <div
        className={
          disabled ? Styles.uploaderContainerDisabled : Styles.uploaderContainer
        }
        onClick={e => {
          if (disabled) {
            e.preventDefault()
            e.stopPropagation()
          }
        }}
      >
        <div className={Styles.uploaderInnerContainer}>
          <div className={Styles.uploaderRequirement}>
            {t('charities.register.upload_required.requirement')}
          </div>
          <div className={Styles.uploaderTitle}>
            {t('charities.register.upload_required.title')}
          </div>
          <div className={Styles.uploaderDescription}>
            {t('charities.register.upload_required.description')}
          </div>
          <div className={Styles.uploaderMediaRequirements}>
            {t('charities.register.upload_required.media_requirements')}
          </div>
          <div className={Styles.uploaderButton}>
            {t('charities.register.upload_required.button')}
          </div>
        </div>
      </div>
    )
  }, [isUploadingCheck, checkAttachment])

  return (
    <div className={Styles.charityRegisterForm}>
      <div className={Styles.heading}>{t('charities.register.title')}</div>
      <small>{t('charities.register.message')}</small>

      <div className={Styles.listContainer}>
        <div className={Styles.list}>
          <CharitySearchRow charity={charity} />
        </div>
      </div>

      <div className={Styles.sectionHeading}>
        {t('charities.register.bank_section_title')}
      </div>

      <small>{t('charities.register.bank_section_message')}</small>

      <div className={Styles.registerForm}>
        <div className="form-group">
          <label htmlFor="accountHolderName">
            {t('charities.register.bank_account_holder_name_label')}
          </label>
          <TextInput
            autoFocus
            id="accountHolderName"
            isInvalid={validationErrors.accountHolderName}
            // placeholder={t('charities.register.bank_account_holder_name_label')}
            onChange={text => {
              setValidationError('accountHolderName', false)
              setBankAccount({
                ...bankAccount,
                bank_account_holder_name: text,
              })
            }}
            validationErrorContainerStyle={{ marginTop: '-10px' }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="bankRoutingNumber">
            {t('charities.register.bank_routing_number_label')}{' '}
            <small>
              {t('charities.register.bank_routing_number_label_small')}
            </small>
          </label>
          <TextInput
            id="bankRoutingNumber"
            isInvalid={validationErrors.bankRoutingNumber}
            // placeholder={t('charities.register.bank_routing_number_label')}
            minLength={9}
            maxLength={9}
            onChange={text => {
              setValidationError('bankRoutingNumber', false)
              setBankAccount({
                ...bankAccount,
                bank_account_routing_number: text,
              })
            }}
            validationErrorContainerStyle={{ marginTop: '-10px' }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="bankAccountNumber">
            {t('charities.register.bank_account_number_label')}{' '}
            <small>
              {t('charities.register.bank_account_number_label_small')}
            </small>
          </label>
          <TextInput
            id="bankAccountNumber"
            isInvalid={validationErrors.bankAccountNumber}
            // placeholder={t('charities.register.bank_account_number_label')}
            minLength={12}
            maxLength={17}
            onChange={text => {
              setValidationError('bankAccountNumber', false)
              setBankAccount({
                ...bankAccount,
                bank_account_number: text,
              })
            }}
            validationErrorContainerStyle={{ marginTop: '-10px' }}
          />
        </div>
      </div>

      <div className={Styles.uploadsContainer}>
        <div className={Styles.uploaderColumn}>
          <Uploader
            accept="image/*,application/pdf"
            renderButton={renderUploaderButtonCheck()}
            disabled={isUploadingCheck || !!checkAttachment}
            mediaAttachments={checkAttachment}
            extraAttachmentData={{
              media_attachment_type: 'charity_check',
            }}
            multiple={false}
            onUploadStart={file => {
              setIsUploadingCheck(true)
            }}
            onUploadComplete={response => {
              setIsUploadingCheck(false)
              setCheckAttachment(response)
            }}
            onRemove={() => {
              setIsUploadingCheck(false)
              setCheckAttachment(null)
            }}
            type="private"
          />
        </div>
      </div>

      <div className={Styles.terms}>
        {t('charities.register.terms.section1')}
        <a
          target="_blank"
          href={t('charities.register.terms.section2.link')}
          rel="noreferrer"
        >
          {t('charities.register.terms.section2.text')}
        </a>
        {t('charities.register.terms.section3')}
        <a
          target="_blank"
          href={t('charities.register.terms.section4.link')}
          rel="noreferrer"
        >
          {t('charities.register.terms.section4.text')}
        </a>
        {t('charities.register.terms.section5')}
      </div>

      <div className={Styles.actionsContainer}>
        <button
          className="btn btn-secondary me-3"
          onClick={goBack}
          type="button"
        >
          {t('charities.register.cancel_button')}
        </button>
        <button
          className="btn btn-primary"
          disabled={!canSubmit}
          onClick={onClickSubmit}
          type="submit"
        >
          {t('charities.register.submit_button')}
        </button>
      </div>

      <Modal show={isSuccessModalVisible} onHide={onHideSuccessModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('charities.register.success_title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('charities.register.success_message_part1')}
          <br />
          <br />
          {t('charities.register.success_message_part2')}{' '}
          <b>{currentUser?.email}</b>.{' '}
          {t('charities.register.success_message_part3')}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onHideSuccessModal}>
            {t('charities.register.success_button')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

CharityRegisterForm.defaultProps = {
  charity: {},
}

CharityRegisterForm.propTypes = {
  charity: CharityType,
}
